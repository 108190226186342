/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useId, Field } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Wrapper } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { SelectPara } from "../Modules/InstantReport";
import { formatDate } from "../../utils/formatDate";

interface Props {
  name: string;
  label: string;
  valueType: string;
  isMandatory: boolean;
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const ParaDate: React.FC<Props> = ({ name, label, valueType, isMandatory, selectedPara, setSelectedPara }) => {
  const dateId = useId(`para-date_${name}`);
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];
  const [value, setValue] = useState<Date | null | undefined>(null);

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: formatDate(value), isMandatory: isMandatory, type: valueType, err: null },
    }));
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setValue(null);
    }
  };

  const onParseDateFromString = useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }
      return new Date(year, month, day);
    },
    [value]
  );

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={dateId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field cannot be empty" : null}
      >
        <DatePicker
          aria-labelledby={dateId}
          id={dateId}
          allowTextInput
          placeholder="dd.mm.yyyy"
          value={value}
          onChange={handleChange}
          onSelectDate={setValue}
          formatDate={formatDate}
          parseDateFromString={onParseDateFromString}
          size="large"
        />
      </Field>
    </Wrapper>
  );
};

export default ParaDate;
