import React from "react";
import styled from "styled-components";
import { tokens, Tab, TabList, SelectTabEvent } from "@fluentui/react-components";
import { DocumentTable24Regular, DataUsage24Regular, Mail24Regular } from "@fluentui/react-icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background-color: ${tokens.colorNeutralBackground1};
  border-bottom: solid 1px ${tokens.colorNeutralStroke1};
`;

interface Props {
  selectedValue: "tableWindow" | "instantReport" | "mailing";
  setSelectedValue: React.Dispatch<React.SetStateAction<"tableWindow" | "instantReport" | "mailing">>;
}

const MainTabs: React.FC<Props> = ({ selectedValue, setSelectedValue }) => {
  const onTabSelect = (e: SelectTabEvent, data: any) => {
    e.preventDefault();
    setSelectedValue(data.value);
  };

  const tabList = [
    {
      id: "main-tab_load-table",
      icon: <DocumentTable24Regular />,
      value: "tableWindow",
      txt: "Table Window",
      disabled: true,
    },
    {
      id: "main-tab_load-report",
      icon: <DataUsage24Regular />,
      value: "instantReport",
      txt: "Instant Report",
      disabled: false,
    },
    {
      id: "main-tab_mailing",
      icon: <Mail24Regular />,
      value: "mailing",
      txt: "Mailing",
      disabled: true,
    },
  ];

  return (
    <Wrapper>
      <TabList className="tab-list" selectedValue={selectedValue} onTabSelect={onTabSelect}>
        {tabList.map(({ id, icon, value, txt, disabled }) => (
          <Tab key={id} icon={icon} value={value} disabled={disabled} className="tab">
            {txt}
          </Tab>
        ))}
      </TabList>
    </Wrapper>
  );
};

export default MainTabs;
