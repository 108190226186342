import React, { useEffect } from "react";
import { useId, Field, Input, InputProps } from "@fluentui/react-components";
import CustomLabel from "./CustomLabel";
import { Wrapper } from "./styles.common";
import { SelectPara } from "../Modules/InstantReport";

interface Props {
  name: string;
  label: string;
  valueType: string;
  defaultValue: string;
  isMandatory: boolean;
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const FreeText: React.FC<Props> = ({
  name,
  label,
  valueType,
  defaultValue,
  isMandatory,
  selectedPara,
  setSelectedPara,
}) => {
  const inputId = useId(`para-freetext_${name}`);
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: defaultValue, isMandatory: isMandatory, type: valueType, err: null },
    }));
  }, []);

  const handleChange: InputProps["onChange"] = (e) => {
    const trimmedValue = e.target.value.trim();
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: trimmedValue, err: null },
    }));
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={inputId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field cannot be empty" : null}
      >
        <Input
          aria-labelledby={inputId}
          id={inputId}
          placeholder="..."
          value={
            targetSelectedParaObj
              ? typeof targetSelectedParaObj.value === "string"
                ? targetSelectedParaObj.value
                : ""
              : ""
          }
          onChange={handleChange}
          size="large"
        />
      </Field>
    </Wrapper>
  );
};

export default FreeText;
