/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  useToastController,
  Toast,
  ToastTitle,
  ToastTrigger,
  ToastBody,
  Text,
  ToastFooter,
  Toaster,
  Link,
  Button,
  ToastIntent,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { Wrapper, FieldsWrapper } from "./styles.common";
import { Col } from "../Fields/styles.common";
import Loader from "../Fields/Loader";
import Select from "../Fields/Select";
import Buttons from "../Buttons";
import Message from "../Message";
import { fetchTblListData, loadTblStructure } from "../../utils/apiCalls";
import { importTableIntoExcel } from "../../officeDocuments/table-document";
import { defaultContactLink } from "../../utils/link";
import StandardCheckbox from "../Fields/StandardCheckbox";
import { messageList } from "../../utils/messageList";

interface SelectedTbl {
  displayName: string;
  storeName: string;
  err: null | string;
}

interface TblList {
  id: string;
  name: string;
  displayName: string;
  isHidden: boolean;
}

const TableWindow = () => {
  const [isTblListLoading, setTblListLoading] = useState<boolean>(true);
  const [tblList, setTblList] = useState<TblList[]>([]);
  const [tblListErr, setTblListErr] = useState<boolean>(false);

  const [isHiddenTblShow, setHiddenTblShow] = useState<boolean>(false);
  const [selectedTbl, setSelectedTbl] = useState<SelectedTbl>({ displayName: "", storeName: "", err: null });
  const [isTblClearClicked, setTblClearClicked] = useState<boolean>(false);

  const [isSelectedTblLoading, setSelectedTblLoading] = useState<boolean>(false);
  const [selectedTblLoaded, setSelectedTblLoaded] = useState<boolean>(false);
  const [selectedTblErr, setSelectedTblErr] = useState<boolean>(false);
  const [isSelectedTblEmpty, setSelectedTblEmpty] = useState<boolean>(false);

  const [toastIntent, setToastIntent] = useState<ToastIntent | "none">("none");

  const [toasterId, setToastId] = useState<string>(crypto.randomUUID());
  const { dispatchToast } = useToastController(toasterId);

  const getTblList = async () => {
    try {
      let sortedTblData;
      setTblListErr(false);
      setTblList([]);

      sortedTblData = await fetchTblListData();

      setTblList(sortedTblData);
      setTblListLoading(false);
    } catch (err: any) {
      setTblListLoading(false);
      setTblList([]);
      setTblListErr(err);
    }
  };

  const loadTable = async (selectedTblName: string) => {
    try {
      setSelectedTblLoading(true);
      setSelectedTblLoaded(false);
      setSelectedTblErr(false);
      setSelectedTblEmpty(false);

      const data = await loadTblStructure(selectedTblName);
      importTableIntoExcel(data);

      if (data.length === 0) {
        setSelectedTblEmpty(true);
      } else {
        setSelectedTblEmpty(false);
      }

      setSelectedTblLoaded(true);
      setSelectedTblLoading(false);
    } catch (err: any) {
      setSelectedTblLoading(false);
      setSelectedTblLoaded(false);
      setSelectedTblEmpty(false);
      setSelectedTblErr(err);
    }
  };

  useEffect(() => {
    if (isTblListLoading) {
      getTblList();
    }
  }, [isTblListLoading]);

  const handleTblListChange = () => {
    setHiddenTblShow((prev) => !prev);
  };

  const handleTblChange = (name: string, value: string) => {
    setSelectedTbl({ displayName: name, storeName: value, err: null });
  };

  const handleClearClick = () => {
    setTblClearClicked(true);
    setHiddenTblShow(false);
    setSelectedTbl({ displayName: "", storeName: "", err: null });
  };

  const handleLoadClick = () => {
    if (!checkValid()) return;
    loadTable(selectedTbl.storeName);
  };

  const checkValid = () => {
    let valid = true;

    if (selectedTbl.err) {
      valid = false;
      return;
    }

    if (selectedTbl?.storeName.length === 0) {
      setSelectedTbl({ displayName: "", storeName: "", err: "empty" });
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    if (selectedTblLoaded) {
      if (isSelectedTblEmpty) {
        setToastIntent("warning");
      } else {
        setToastIntent("success");
      }
      setToastId(crypto.randomUUID());
      return;
    }

    if (selectedTblErr) {
      setToastIntent("error");
      setToastId(crypto.randomUUID());
      return;
    }
  }, [isSelectedTblEmpty, selectedTblLoaded, selectedTblErr]);

  useEffect(() => {
    if (toastIntent !== "none") {
      dispatchToast(
        <Toast>
          {selectedTblLoaded && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                {isSelectedTblEmpty ? "Empty table" : " Table loaded successfully"}
              </ToastTitle>
              {isSelectedTblEmpty && (
                <ToastBody>
                  <Text>The table is empty. No data available.</Text>
                </ToastBody>
              )}
            </>
          )}

          {selectedTblErr && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                Error
              </ToastTitle>
              <ToastBody>
                <Text>Please try again. If the issue persists, please contact us for further assistance.</Text>
              </ToastBody>
              <ToastFooter>
                <Link href={`mailto:${defaultContactLink}`}>Contact us</Link>
              </ToastFooter>
            </>
          )}
        </Toast>,
        { position: "top", intent: toastIntent }
      );
      setToastIntent("none");
    }
  }, [toastIntent]);

  return (
    <Wrapper>
      <FieldsWrapper>
        {isTblListLoading ? (
          <Col>
            <Loader />
          </Col>
        ) : tblListErr ? (
          <Col>
            <Message
              type="error"
              title="Error"
              desc={messageList.loadErr}
              isMainBtn={true}
              mainBtnTxt="Reload"
              handleMainBtnClick={() => setTblListLoading(true)}
            />
          </Col>
        ) : (
          <>
            <Col className="mb-6">
              <StandardCheckbox
                label="Show all hidden tables"
                isMandatory={false}
                isChecked={isHiddenTblShow}
                handleChange={handleTblListChange}
              />
            </Col>
            <Select
              name="tbl"
              label="Table name"
              isMandatory={true}
              isDisabled={false}
              err={selectedTbl.err}
              optionList={tblList}
              optionListHiddenShow={isHiddenTblShow}
              nameDisplay="displayName"
              nameStore="name"
              optionDesc="name"
              optionSelectChange={handleTblChange}
              isClearClicked={isTblClearClicked}
              setClearClicked={setTblClearClicked}
            />
          </>
        )}
      </FieldsWrapper>

      {!isTblListLoading && !tblListErr && (
        <Buttons
          mainBtnTxt="Load table"
          secondaryBtnTxt="Clear all"
          isMainBtnLoading={isSelectedTblLoading}
          isMainBtnDisabled={selectedTbl.displayName.length === 0}
          isSecondaryBtnDisabled={isSelectedTblLoading}
          handleConfirmClick={handleLoadClick}
          handleCancelClick={handleClearClick}
        />
      )}
      <Toaster toasterId={toasterId} />
    </Wrapper>
  );
};
export default TableWindow;
