/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import {
  useToastController,
  Divider,
  Toast,
  ToastTitle,
  ToastTrigger,
  ToastBody,
  Text,
  ToastFooter,
  Toaster,
  Link,
  Button,
  ToastIntent,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import styled from "styled-components";
import { Wrapper, FieldsWrapper } from "./styles.common";
import { Col } from "../Fields/styles.common";
import Loader from "../Fields/Loader";
import StandardCheckbox from "../Fields/StandardCheckbox";
import Select from "../Fields/Select";
import ParaSelect from "../Fields/ParaSelect";
import ParaMultiselect from "../Fields/ParaMultiselect";
import FreeText from "../Fields/FreeText";
import ParaCheckbox from "../Fields/ParaCheckbox";
import Buttons from "../Buttons";
import ParaDate from "../Fields/ParaDate";
import Message from "../Message";
import { importInstantReportIntoExcel } from "../../officeDocuments/report-document";
import { fetchIntRptListData, fetchIntRptParaData, loadIntRptData } from "../../utils/apiCalls";
import { multiselectJoinedChar } from "../../utils/separators";
import { ClientContext } from "../App";
import { messageList } from "../../utils/messageList.js";
import { ClientInfoContext } from "../../models/client";
// import Preview from "../Preview";

const ParaWrapper = styled.div`
  padding: 26px 0 12px;
`;

interface SelectedRpt {
  displayName: string;
  storeName: string;
  err: null | string;
}

interface RptList {
  name: string;
  displayName: string;
  isHidden: boolean;
}

interface RptPara {
  isParaMandatory: boolean;
  paraControlType: string;
  paraDefault: string;
  paraKeys: any[];
  paraLabel: string;
  paraName: string;
  paraSize: number;
  paraType: string;
  valueList: any[];
  valueType: string;
}

export interface SelectPara {
  [key: string]: {
    err: null | string;
    isMandatory: boolean;
    type: string;
    value: string | string[] | number;
  };
}

const InstantReport = () => {
  const clientInfo: ClientInfoContext = useContext(ClientContext);

  const [isRptListLoading, setRptListLoading] = useState<boolean>(true);
  const [rptList, setRptList] = useState<RptList[] | []>([]);
  const [rptListErr, setRptListErr] = useState<boolean>(false);

  const [isHiddenRptShow, setHiddenRptShow] = useState<boolean>(false);
  const [selectedRpt, setSelectedRpt] = useState<SelectedRpt>({ displayName: "", storeName: "", err: null });
  const [isRptClearClicked, setRptClearClicked] = useState<boolean>(false);

  const [isParaListLoading, setParaListLoading] = useState<boolean>(false);
  const [paraList, setParaList] = useState<RptPara[] | any[]>([]);
  const [paraListErr, setParaListErr] = useState<boolean>(false);
  const [selectedPara, setSelectedPara] = useState<SelectPara>({});

  const [isSelectedRptLoading, setSelectedRptLoading] = useState<boolean>(false);
  const [selectedRptLoaded, setSelectedRptLoaded] = useState<boolean>(false);
  const [selectedRptErr, setSelectedRptErr] = useState<boolean>(false);
  const [isSelectedRptEmpty, setSelectedRptEmpty] = useState<boolean>(false);
  const [toastIntent, setToastIntent] = useState<ToastIntent | "none">("none");

  const [toasterId, setToastId] = useState<string>(crypto.randomUUID());
  const { dispatchToast } = useToastController(toasterId);

  const getIntRptList = async () => {
    try {
      setRptListErr(false);
      setRptList([]);

      const rptData = await fetchIntRptListData();

      setRptList(rptData);
      setRptListLoading(false);
    } catch (err: any) {
      setRptListLoading(false);
      setRptList([]);
      setRptListErr(err);
    }
  };

  const getIntRptPara = async (selectedRpt: any) => {
    try {
      setParaListLoading(true);
      setParaListErr(false);
      setParaList([]);
      setSelectedPara({});

      const paraData = await fetchIntRptParaData(selectedRpt);

      setParaList(paraData);
      setParaListLoading(false);
    } catch (err: any) {
      setParaListLoading(false);
      setParaList([]);
      setParaListErr(err);
    }
  };

  const loadReport = async (selectedIntRptName: string, paraArr: any[]) => {
    try {
      setSelectedRptLoading(true);
      setSelectedRptLoaded(false);
      setSelectedRptErr(false);
      setSelectedRptEmpty(false);

      const data = await loadIntRptData(selectedIntRptName, paraArr);
      importInstantReportIntoExcel(data);

      if (data.length === 0) {
        setSelectedRptEmpty(true);
      } else {
        setSelectedRptEmpty(false);
      }

      setSelectedRptLoaded(true);
      setSelectedRptLoading(false);
    } catch (err: any) {
      setSelectedRptLoading(false);
      setSelectedRptLoaded(false);
      setSelectedRptEmpty(false);
      setSelectedRptErr(err);
    }
  };

  useEffect(() => {
    if (isRptListLoading) {
      getIntRptList();
    }
  }, [isRptListLoading]);

  const handleRptListChange = () => {
    setHiddenRptShow((prev) => !prev);
  };

  const handleIntRptChange = (name: string, value: string) => {
    setSelectedRpt({ displayName: name, storeName: value, err: null });
    if (value.length > 0) {
      getIntRptPara(value);
    }
  };

  const handleClearClick = () => {
    setRptClearClicked(true);
    setHiddenRptShow(false);
    setSelectedRpt({ displayName: "", storeName: "", err: null });
    setParaList([]);
    setSelectedPara({});
  };

  const handleLoadClick = () => {
    if (!checkValid()) return;
    let paraArr: string[] = [];
    for (const key in selectedPara) {
      if (Object.prototype.hasOwnProperty.call(selectedPara, key)) {
        const selectedParaValue = selectedPara[key].value;

        if (typeof selectedParaValue === "string" && selectedParaValue.length > 0) {
          paraArr.push(`${key}='${encodeURIComponent(selectedParaValue)}'`);
        } else if (typeof selectedParaValue === "number" && selectedParaValue === 1) {
          paraArr.push(`${key}='${encodeURIComponent(selectedParaValue)}'`);
        } else if (Array.isArray(selectedParaValue) && selectedParaValue.length > 0) {
          const encodedArr = selectedParaValue?.map((value: string | number) => encodeURIComponent(value));
          const encodedStr = encodedArr.join(encodeURIComponent(multiselectJoinedChar));
          paraArr.push(`${key}='${encodedStr}'`);
        }
      }
    }

    loadReport(selectedRpt.storeName, paraArr);
  };

  const checkValid = () => {
    let valid = true;

    if (selectedRpt.err) {
      valid = false;
      return;
    }

    if (selectedRpt?.storeName.length === 0) {
      setSelectedRpt({ displayName: "", storeName: "", err: "empty" });
      valid = false;
    }

    setSelectedPara((prev) => {
      const copiedObj = { ...prev };
      for (const key in copiedObj) {
        if (Object.prototype.hasOwnProperty.call(copiedObj, key)) {
          if (copiedObj[key].isMandatory) {
            const copiedSelectedParaValue = copiedObj[key].value;
            if (typeof copiedSelectedParaValue === "string" && copiedSelectedParaValue.length === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else if (typeof copiedSelectedParaValue === "number" && copiedSelectedParaValue === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else if (Array.isArray(copiedSelectedParaValue) && copiedSelectedParaValue.length === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else {
              copiedObj[key].err = null;
            }
          }
        }
      }
      return copiedObj;
    });

    return valid;
  };

  useEffect(() => {
    if (selectedRptLoaded) {
      if (isSelectedRptEmpty) {
        setToastIntent("warning");
      } else {
        setToastIntent("success");
      }
      setToastId(crypto.randomUUID());
      return;
    }

    if (selectedRptErr) {
      setToastIntent("error");
      setToastId(crypto.randomUUID());
      return;
    }
  }, [isSelectedRptEmpty, selectedRptLoaded, selectedRptErr]);

  useEffect(() => {
    if (selectedRpt.displayName.length === 0) {
      setParaList([]);
      setSelectedPara({});
    }
  }, [selectedRpt.displayName]);

  useEffect(() => {
    if (toastIntent !== "none") {
      dispatchToast(
        <Toast>
          {selectedRptLoaded && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                {isSelectedRptEmpty ? "Empty report" : " Report loaded successfully"}
              </ToastTitle>
              {isSelectedRptEmpty && (
                <ToastBody>
                  <Text>The report is empty. No data available.</Text>
                </ToastBody>
              )}
            </>
          )}

          {selectedRptErr && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                Error
              </ToastTitle>
              <ToastBody>
                <Text>Please try again. If the issue persists, please contact us for further assistance.</Text>
              </ToastBody>
              <ToastFooter>
                <Link href={`mailto:${clientInfo.contact}`}>Contact us</Link>
              </ToastFooter>
            </>
          )}
        </Toast>,
        { position: "top", intent: toastIntent }
      );
      setToastIntent("none");
    }
  }, [toastIntent]);

  return (
    <Wrapper>
      <FieldsWrapper>
        {isRptListLoading ? (
          <Col>
            <Loader />
          </Col>
        ) : rptListErr ? (
          <Col>
            <Message
              type="error"
              title="Error"
              desc={messageList.loadErr}
              isMainBtn={true}
              mainBtnTxt="Reload"
              handleMainBtnClick={() => setRptListLoading(true)}
            />
          </Col>
        ) : (
          <>
            <Col className="mb-6">
              <StandardCheckbox
                label="Show all hidden reports"
                isMandatory={false}
                isChecked={isHiddenRptShow}
                handleChange={handleRptListChange}
              />
            </Col>
            <Select
              name="intRpt"
              label="Report name"
              isMandatory={true}
              isDisabled={isParaListLoading}
              err={selectedRpt.err}
              optionList={rptList}
              optionListHiddenShow={isHiddenRptShow}
              nameDisplay="displayName"
              nameStore="name"
              optionDesc={null}
              optionSelectChange={handleIntRptChange}
              isClearClicked={isRptClearClicked}
              setClearClicked={setRptClearClicked}
            />
          </>
        )}

        {selectedRpt.storeName.length > 0 && <Divider>Parameters</Divider>}

        <ParaWrapper>
          {isParaListLoading ? (
            <Col>
              <Loader />
            </Col>
          ) : paraListErr ? (
            <Col>
              <Message
                type="error"
                title="Error"
                desc={messageList.loadErr}
                isMainBtn={true}
                mainBtnTxt="Reload"
                handleMainBtnClick={() => getIntRptPara(selectedRpt.storeName)}
              />
            </Col>
          ) : paraList?.length === 0 ? (
            selectedRpt.storeName.length > 0 && <Col>No parameters</Col>
          ) : (
            paraList.map(
              ({ paraName, paraLabel, paraDefault, isParaMandatory, valueType, paraSize, paraKeys, valueList }, idx) =>
                valueType === "select" ? (
                  <ParaSelect
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    size={paraSize}
                    optionListKeys={paraKeys}
                    optionList={valueList}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "date" ? (
                  <ParaDate
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "checkbox" ? (
                  <ParaCheckbox
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "freetext" ? (
                  <FreeText
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "multiselect" ? (
                  <ParaMultiselect
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    isMandatory={isParaMandatory}
                    optionListKeys={paraKeys}
                    optionList={valueList}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : null
            )
          )}
        </ParaWrapper>
        {/*{Object.keys(selectedPara).length > 0 && (*/}
        {/* <Preview rptName={selectedRpt.displayName} selectedPara={selectedPara} /> */}
        {/* )} */}
      </FieldsWrapper>

      {!isRptListLoading && !rptListErr && !paraListErr && (
        <Buttons
          mainBtnTxt="Load report"
          secondaryBtnTxt="Clear all"
          isMainBtnLoading={isSelectedRptLoading}
          isMainBtnDisabled={selectedRpt.displayName.length === 0 || isParaListLoading}
          isSecondaryBtnDisabled={isSelectedRptLoading || isParaListLoading}
          handleConfirmClick={handleLoadClick}
          handleCancelClick={handleClearClick}
        />
      )}
      <Toaster toasterId={toasterId} />
    </Wrapper>
  );
};

export default InstantReport;
