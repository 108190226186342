/* eslint-disable */
/* global Excel console */
import { columnWidth, rptInst, rowColors } from "../config/excelConfig";
import { getReportStyle } from "../utils/apiCalls";

/**
 * @param rgbString {string} RGB(int R,int B,int B)
 * @example extractRGBValues('RGB(187,241,241) FILL') returns #BBF1F1
 * @returns {string|null} \#BBF1F1
 */
function extractRGBValues(rgbString) {
  const matches = rgbString.match(/RGB\((\d+),\s*(\d+),\s*(\d+)\)/);

  if (matches && matches.length === 4) {
    const r = parseInt(matches[1]);
    const g = parseInt(matches[2]);
    const b = parseInt(matches[3]);

    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
  } else {
    return null;
  }
}

/**
 * @param vbaHexString {string} &HRRGGBB
 * @ &HAABBCC
 * @returns {string} #CCBBAA
 */
function parseVBAHEX(vbaHexString) {
  if (typeof vbaHexString !== "string") return;
  let modified = vbaHexString.substring(2);
  return "#" + modified.substring(4, 6) + modified.substring(2, 4) + modified.substring(0, 2);
}

async function getDefaultStyle() {
  let resp = await getReportStyle();
  let mappedResp = {};
  for (let i = 0; i < resp[0].length; i++) {
    mappedResp[resp[0][i]["PropertyName"]] = parseVBAHEX(resp[0][i]["StrPropertyValue"]);
  }
  return mappedResp;
}

function rgbToHex(r, g, b) {
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexR = toHex(r);
  const hexG = toHex(g);
  const hexB = toHex(b);

  return `#${hexR}${hexG}${hexB}`;
}

function parseVBAString(input, header = false) {
  const result = {};

  const regexFontBold = /Font\.Bold\s*=\s*(\w+)/;
  const regexHorizontalAlignment = /HorizontalAlignment\s*=\s*(xl\w+)/;
  const regexNumberFormat = /NumberFormat\s*=\s*([^#]+)###|NumberFormat\s*=\s*(.+)/;
  const regexInteriorColor = /Interior\.Color\s*=\s*RGB\((\d+),\s*(\d+),\s*(\d+)\)/;
  const matchInteriorColor = input.match(regexInteriorColor);
  if (matchInteriorColor) {
    input = input.replace(regexInteriorColor, "");
    input = input.replace("###.", "");
    const rgbColor = {
      r: parseInt(matchInteriorColor[1]),
      g: parseInt(matchInteriorColor[2]),
      b: parseInt(matchInteriorColor[3]),
    };
    //header darker
    if (header === true) {
      rgbColor.r = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[1]), 2.2) * 0.85, 1 / 2.2));
      rgbColor.g = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[2]), 2.2) * 0.85, 1 / 2.2));
      rgbColor.b = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[3]), 2.2) * 0.85, 1 / 2.2));
    }
    result.interiorColor = rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b);
  }
  const matchHorizontalAlignment = input.match(regexHorizontalAlignment);
  if (matchHorizontalAlignment) {
    input = input.replace(regexHorizontalAlignment, "");
    input = input.replace("###.", "");
    result.horizontalAlignment = matchHorizontalAlignment[1];
  }
  const matchFontBold = input.match(regexFontBold);
  if (matchFontBold) {
    input = input.replace(regexFontBold, "");
    input = input.replace("###.", "");
    result.fontBold = matchFontBold[1] === "True";
  }

  const matchNumberFormat = input.match(regexNumberFormat);

  if (matchNumberFormat) result.numberFormat = (matchNumberFormat[1] || matchNumberFormat[2]).trim();

  return result;
}

export const importInstantReportIntoExcel = async (report) => {
  if (report.length === 0) return;
  try {
    await Excel.run(async (context) => {
      OfficeExtension.config.debugInfo = true;
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      console.log(report);
      worksheet.getUsedRange().clear(Excel.ClearApplyTo.all);
      worksheet.getUsedRange().format.rowHeight = 20;
      worksheet.getRange("A1").format.columnWidth = columnWidth[3];
      worksheet.showGridlines = false;
      const response = report;
      const responseKeys = Object.keys(response[0]);
      const epKey = responseKeys[0];
      //insert metadata
      //B1:B4 or B1:B3
      let metadataRange = "B1:B";
      let metadataRangeNumber = 3;
      let headerRowNumber;
      let colsizeRowNumber;
      let formatRowNumber;
      let paraRowNumber;
      for (let i=0;i<7;i++){
        if (response[i][epKey].includes('#HEADER#')) {
          headerRowNumber = i;
          metadataRangeNumber = i;
          continue;
        }
        if (response[i][epKey].includes('#COLSIZE#')) {
          colsizeRowNumber = i;
          continue;
        }
        if (response[i][epKey].includes('#FORMAT#')) {
          formatRowNumber = i;
          continue;
        }
        if (response[i][epKey].includes('#PARA#')) {
          paraRowNumber = i;
        }
      }
      metadataRange += metadataRangeNumber;
      worksheet.freezePanes.unfreeze();
      if (headerRowNumber !== undefined)
        worksheet.freezePanes.freezeRows(headerRowNumber+1);
      const metaDataValues = [];
      for (let i = 0; i < metadataRangeNumber; i++) {
        metaDataValues.push([response[i][responseKeys[1]]]);
      }
      worksheet.getRange(metadataRange).values = metaDataValues;

      //format metadata
      //B1:B4 and clear B4 if not needed
      for (const metaDataKey in rptInst.metaData) {
        worksheet.getRange(rptInst.metaData[metaDataKey].range).format.font.bold =
            rptInst.metaData[metaDataKey].font.bold;
        worksheet.getRange(rptInst.metaData[metaDataKey].range).format.font.size =
            rptInst.metaData[metaDataKey].font.size;
      }
      if (metadataRangeNumber === 3) worksheet.getRange("B4").clear(Excel.ClearApplyTo.all);

      //=====TABLE=====
      //1. Create table
      //2. Insert data
      //3. Set default format
      //4. Set individual report format

      let headerRow = [];

      if (headerRowNumber !== undefined) {
        headerRow = Object.values(response[metadataRangeNumber]);
        let underScoresIndex = 1;
        headerRow.forEach((value, index) => {
          if (value === "__") {
            headerRow[index] = " ".repeat(underScoresIndex);
            underScoresIndex++;
          }
        });
      }

      const colSizes = Object.values(response[colsizeRowNumber]);
      const formats = Object.values(response[formatRowNumber]);

      colSizes.shift();
      formats.shift();
      if (headerRow.length > 0) headerRow.shift();

      let rowFormatting = [];
      let dataArray = [];
      if  (metadataRangeNumber)
      dataArray = response.slice(metadataRangeNumber + 3, response.length);
      dataArray.forEach((obj) => {
        if (epKey) {
          rowFormatting.push(obj[epKey]);
          delete obj[epKey];
        }
      });

      for (let i = 0; i < dataArray.length; i++) dataArray[i] = Object.values(dataArray[i]);
      if (headerRow.length > 0) dataArray.unshift(headerRow);

      const tableRange = worksheet
          .getRange(`B${metadataRangeNumber + 1}`)
          .getResizedRange(dataArray.length - 1, Object.keys(dataArray[0]).length - 1);
      tableRange.values = dataArray;

      let hasHeader = headerRow.length > 0;
      const table = worksheet.tables.add(tableRange, hasHeader);
      table.showHeaders = hasHeader;
      //styles
      let defaultStyles = await getDefaultStyle();
      table.getRange().format.fill.clear();
      table.getDataBodyRange().format.fill.color = defaultStyles["InteriorColorMainSheet"];
      table.getDataBodyRange().format.font.color = defaultStyles["InteriorColorBodyTxt"];
      table.getDataBodyRange().format.rowHeight = 20;
      table.getDataBodyRange().format.font.size = 8;

      for (let border of rptInst.body.border.outline) {
        table.getDataBodyRange().format.borders.getItem(border).color = defaultStyles["InteriorColorIRBorder"];
        table.getDataBodyRange().format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
      }

      //header style
      if (hasHeader) {
        table.getHeaderRowRange().format.fill.color = defaultStyles["InteriorColorIRHeader"];
        table.getHeaderRowRange().format.rowHeight = 30;
        table.getHeaderRowRange().format.font.color = defaultStyles["InteriorColorHeaderTxt"];
        table.getHeaderRowRange().format.wrapText = true;
        table.getHeaderRowRange().format.horizontalAlignment = Excel.HorizontalAlignment.center;
        table.getHeaderRowRange().format.verticalAlignment = Excel.VerticalAlignment.center;
        table.getHeaderRowRange().format.font.size = 8;

        for (let border of rptInst.header.border.outline) {
          table.getHeaderRowRange().format.borders.getItem(border).color = defaultStyles["InteriorColorIRBorder"];
          table.getHeaderRowRange().format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
        }


        //darker header color
        formats.forEach((value, index) => {
          if (value.includes("RGB")) {
            let parsedValue = parseVBAString(value, false);
            table.getHeaderRowRange().getColumn(index).format.fill.color = parsedValue.interiorColor;
          }
        });
      }

      //row formatting
      const colorPattern = /#(?:[A-Z_]+_)?(FILL|LINE|SUBHEADER)#/;
      const horizontalAlignmentPattern = /HorizontalAlignment\s*=\s*(\w+)/;
      const verticalAlignmentPattern = /VerticalAlignment\s*=\s*(\w+)/;

      const fontSizePattern = /\.Font\.Size\s*=\s*(\d+)/;
      const fontBoldPattern = /Font.Bold\s*=\s*(\w+)/;
      const fontColorPattern = /Font\.Color\s*=\s*RGB\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)/;
      const fontUnderlinePattern = /Font\.Underline\s*=\s*(\w+)/;
      const fontWrapTextPattern = /Font\.WrapText\s*=\s*(\w+)/;
      const fontShrinkToFitPattern = /ShrinkToFit\s*=\s*(\w+)/;
      const fontNamePattern = /Font.Name\s*=\s*(\w+)/;
      const fontStylePattern = /Font.Style\s*=\s*(\w+)/;
      const fontStyleStrikethroughPattern = /Font.Strikethrough\s*=\s*(\w+)/;
      const fontStyleSubscriptPattern = /Font.Subscript\s*=\s*(\w+)/;

      const rowIndentLevelPattern = /IndentLevel\s*=\s*(\d+)/;
      const rowHeightPattern = /RowHeight\s*=\s*(\d+)/;
      for (let i = 0; i < rowFormatting.length; i++) {
        const rowFormatObject = {
          rowColor: '#FFFFFF',
          rowHeight: 20,
          horizontalAlignment: 'xlCenter',
          verticalAlignment: 'xlCenter',
          fontSize: 8,
          fontBold: false,
          fontUnderline: null,
          fontColor: '#000000',
          fontStyle: null,
          fontName: null,
          fontStrikethrough: false,
          fontSubscript: false,
          shrinkToFit: false,
          wrapText: true,
          indentLevel: 0,
          borderLineStyle: 'xlDot',
          borderColor: 'InteriorColorIRBorder'
        };

        if (rowFormatting[i] !== null && rowFormatting[i] !== '') {
          let formatValue = rowFormatting[i];
          let rowColorMatch = formatValue.match(colorPattern);

          if (rowColorMatch) {
            if (rowColorMatch[0].includes('LINE')) {
              rowFormatObject.rowHeight = 3;
              table.getDataBodyRange().getRow(i).values = "";
            }
            //set white background if there's problem with color pattern matching
            if (rowColors[rowColorMatch[0]] === undefined) {
              console.log(`Error! Couldn't find color in: ${rowColorMatch[0]} on row ${i}, set #FFFFFF`);
              rowFormatObject.rowColor = '#FFFFFF';
            } else {
              if (rowColors[rowColorMatch[0]].startsWith('#')) {
                rowFormatObject.rowColor = rowColors[rowColorMatch[0]];
              } else
                rowFormatObject.rowColor = defaultStyles[rowColors[rowColorMatch[0]]];
            }
            formatValue = formatValue.replace(colorPattern, '');
          } else {
            if (formatValue.startsWith('RGB')) {
              if (formatValue.includes('LINE')) {
                rowFormatObject.rowHeight = 3;
                table.getDataBodyRange().getRow(i).values = "";
              }
              rowFormatObject.rowColor = (extractRGBValues(formatValue));
            } else {
              console.log(`Error! Couldn't find color in: ${formatValue} on row ${i}, set #FFFFFF`);
              console.log(formatValue);
              //
              let rowColorDirectRGB = parseVBAString(formatValue);
              if (rowColorDirectRGB) {
                rowColorDirectRGB = rowColorDirectRGB["interiorColor"];
                rowFormatObject.rowColor = rowColorDirectRGB
              }
              else {
                rowFormatObject.rowColor = '#FFFFFF';
              }
            }
          }


          let horizontalAlignmentMatch = formatValue.match(horizontalAlignmentPattern);
          if (horizontalAlignmentMatch) {
            rowFormatObject.horizontalAlignment = horizontalAlignmentMatch[1];
            formatValue = formatValue.replace(horizontalAlignmentPattern, '');
          }

          let verticalAlignmentMatch = formatValue.match(verticalAlignmentPattern);
          if (verticalAlignmentMatch) {
            rowFormatObject.verticalAlignment = verticalAlignmentMatch[1];
            formatValue = formatValue.replace(verticalAlignmentPattern, '');
          }

          let fontSizeMatch = formatValue.match(fontSizePattern);
          if (fontSizeMatch) {
            if (fontSizeMatch[1] % 1 === 0) //check if font size number is int
              rowFormatObject.fontSize = Number(fontSizeMatch[1]);
            else {
              console.log(`Couldn't find fontSize in ${formatValue}, set 8`);
              rowFormatObject.fontSize = 8;
            }
            formatValue = formatValue.replace(fontSizePattern, '');
          }

          let fontBoldMatch = formatValue.match(fontBoldPattern);
          if (fontBoldMatch) {
            rowFormatObject.fontBold = fontBoldMatch[1].toLowerCase() === "true";
            formatValue = formatValue.replace(fontBoldPattern, '');
          }

          let fontWrapTextMatch = formatValue.match(fontWrapTextPattern);
          if (fontWrapTextMatch) {
            rowFormatObject.wrapText = fontWrapTextMatch[1].toLowerCase() === "true";
            formatValue = formatValue.replace(fontWrapTextPattern, '');
          }

          let fontShrinkToFitMatch = formatValue.match(fontShrinkToFitPattern);
          if (fontShrinkToFitMatch) {
            rowFormatObject.shrinkToFit = fontShrinkToFitMatch[1].toLowerCase() === "true";
            formatValue = formatValue.replace(fontShrinkToFitPattern, '');
          }

          let fontNameMatch = formatValue.match(fontNamePattern);
          if (fontNameMatch) {
            rowFormatObject.fontName = fontNameMatch[1];
            formatValue = formatValue.replace(fontNamePattern, '');
          }

          let fontStyleMatch = formatValue.match(fontStylePattern);
          if (fontStyleMatch) {
            rowFormatObject.fontStyle = fontStyleMatch[1];
            formatValue = formatValue.replace(fontStylePattern, '');
          }

          let fontStrikethroughMatch = formatValue.match(fontStyleStrikethroughPattern);
          if (fontStrikethroughMatch) {
            rowFormatObject.fontStrikethrough = fontStrikethroughMatch[1].toLowerCase() === "true";
            formatValue = formatValue.replace(fontStyleStrikethroughPattern, '');
          }

          let fontSubscriptMatch = formatValue.match(fontStyleSubscriptPattern);
          if (fontSubscriptMatch) {
            rowFormatObject.fontSubscript = fontSubscriptMatch[1].toLowerCase() === "true";
            formatValue = formatValue.replace(fontStyleSubscriptPattern, '');
          }

          let fontColorMatch = formatValue.match(fontColorPattern);
          if (fontColorMatch) {
            rowFormatObject.fontColor = extractRGBValues(fontColorMatch[0].substring(fontColorMatch[0].indexOf('RGB('), fontColorMatch[0].indexOf(')') + 1));
            formatValue = formatValue.replace(fontColorPattern, '');
          }

          let fontUnderlineMatch = formatValue.match(fontUnderlinePattern);
          if (fontUnderlineMatch) {
            rowFormatObject.fontUnderline = fontUnderlineMatch[1];
            formatValue = formatValue.replace(fontUnderlinePattern, '');
          }

          let indentLevelMatch = formatValue.match(rowIndentLevelPattern);
          if (indentLevelMatch) {
            rowFormatObject.indentLevel = Number(indentLevelMatch[1]);
            formatValue = formatValue.replace(rowIndentLevelPattern, '');
          }

          let rowHeightMatch = formatValue.match(rowHeightPattern);
          if (rowHeightMatch) {
            if (rowHeightMatch[1] % 1 === 0)
              rowFormatObject.rowHeight = Number(rowHeightMatch[1]);
            formatValue = formatValue.replace(rowHeightPattern, '');
          }
        }

        let rowFormat = table.getDataBodyRange().getRow(i).format;
        rowFormat.rowHeight = rowFormatObject.rowHeight;
        rowFormat.fill.color = rowFormatObject.rowColor;
        rowFormat.horizontalAlignment = Excel.HorizontalAlignment.center;
        rowFormat.verticalAlignment = Excel.VerticalAlignment.center;
        rowFormat.font.color = rowFormatObject.fontColor;
        rowFormat.font.size = rowFormatObject.fontSize;
        rowFormat.font.bold = rowFormatObject.fontBold;
        rowFormat.font.strikethrough = rowFormatObject.fontStrikethrough;
        rowFormat.font.subscript = rowFormatObject.fontSubscript;
        rowFormat.shrinkToFit = rowFormatObject.shrinkToFit;
        rowFormat.wrapText = rowFormatObject.wrapText;
        rowFormat.indentLevel = rowFormatObject.indentLevel;

        if (rowFormatObject.horizontalAlignment !== 'xlCenter')
          switch (rowFormatObject.horizontalAlignment) {
            case "xlLeft":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.left;
              break;
            case "xlCenter":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.center;
              break;
            case "xlRight":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.right;
              break;
            case "xlDistributed":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.distributed;
              break;
            case "xlJustify":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.justify;
              break;
            case "xlGeneral":
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.general;
              break;
            default:
              rowFormat.horizontalAlignment = Excel.HorizontalAlignment.center;
              break;
          }

        if (rowFormatObject.verticalAlignment !== 'xlCenter')
          switch (rowFormatObject.verticalAlignment) {
            case "xlBottom":
              rowFormat.verticalAlignment = Excel.VerticalAlignment.bottom;
              break;
            case "xlTop":
              rowFormat.verticalAlignment = Excel.VerticalAlignment.top;
              break;
            case "xlCenter":
              rowFormat.verticalAlignment = Excel.VerticalAlignment.center;
              break;
            case "xlDistributed":
              rowFormat.verticalAlignment = Excel.VerticalAlignment.distributed;
              break;
            case "xlJustify":
              rowFormat.verticalAlignment = Excel.VerticalAlignment.justify;
              break;
            default:
              rowFormat.verticalAlignment = Excel.VerticalAlignment.center;
              break;
          }

          if (rowFormatObject.fontUnderline !== null){
            switch (rowFormatObject.fontUnderline){
              case "xlUnderlineStyleDouble":
                rowFormat.font.underline = Excel.RangeUnderlineStyle.double;
                break;
              case "xlUnderlineStyleSingle":
                rowFormat.font.underline = Excel.RangeUnderlineStyle.single;
                break;
              default:
                rowFormat.font.underline = Excel.RangeUnderlineStyle.none;
            }
          }

      }

      //columnSizes and format needs to be the same!
      //maybe add some err handling
      //format columns #FORMAT#
      for (let i = 0; i < colSizes.length; i++) {
        // set column width
        if (Object.keys(columnWidth).includes(colSizes[i])) {
          table.getRange().getColumn(i).format.columnWidth = columnWidth[colSizes[i]];
        } else {
          console.log("Couldn't find column size " + colSizes[i] + ". Using automated column size!");
          table.getRange().getColumn(i).format.columnWidth = 5.4 * colSizes[i];
        }

        // set normal excel number formatting
        if (!formats[i].includes("VBA")) {
          // table.getDataBodyRange().getEntireColumn(i).numberFormat = [[formats[i]]];
          if (formats[i] === "Text") table.getDataBodyRange().getColumn(i).numFmt = "text";
          else table.getDataBodyRange().getColumn(i).numberFormat = formats[i];
          table.getDataBodyRange().format.horizontalAlignment = Excel.HorizontalAlignment.center;
        }

        if (formats[i].includes("VBA")) {
          let match = parseVBAString(formats[i]);
          if (match) {
            let alignment = match["horizontalAlignment"];
            let numberFormat = match["numberFormat"];
            let interiorColor = match["interiorColor"];
            let bold = match["fontBold"];

            if (alignment !== undefined) {
              if (alignment === "xlLeft") {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.left;
              } else if (alignment === "xlRight") {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.right;
              } else {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.center;
              }
            }

            if (numberFormat !== undefined) {
              table.getDataBodyRange().getColumn(i).numberFormat = numberFormat;
            }
            if (interiorColor !== undefined) {
              table.getDataBodyRange().getColumn(i).format.fill.color = interiorColor;
            }
            if (bold !== null) {
              table.getDataBodyRange().getColumn(i).format.font.bold = bold;
            }
          }
        }
      }

      //fill vertical #SEP#
      Object.values(response[metadataRangeNumber + 1]).forEach((value, index) => {
        if (value === "#SEP#")
          table.getRange().getColumn(index - 1).format.fill.color = defaultStyles["InteriorColorIRSeparator"];
      });

      return context
          .sync()
          .then(() => console.log("Values added successfully!"))
          .catch((err) => {
            console.error("Error: " + JSON.stringify(err));
          });
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};
