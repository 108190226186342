import React, { useEffect, useState } from "react";
import { Field, Combobox, Option, ComboboxProps } from "@fluentui/react-components";
import { OptionDetails, Wrapper } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { changeListHeight } from "../../utils/changeStyles";

interface Props {
  name: string;
  label: string;
  isMandatory: boolean;
  isDisabled: boolean;
  err: null | string;
  optionList: any[];
  optionListHiddenShow: boolean;
  nameDisplay: string;
  nameStore: string;
  optionDesc: string | null;
  optionSelectChange: (name: string, value: string) => void;
  isClearClicked: boolean;
  setClearClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Select: React.FC<Props> = ({
  name,
  label,
  isMandatory,
  isDisabled,
  err,
  optionList,
  optionListHiddenShow,
  nameDisplay,
  nameStore,
  optionDesc,
  optionSelectChange,
  isClearClicked,
  setClearClicked,
}) => {
  const selectId = `select_${name}`;
  const [selectedValue, setSelectedValue] = useState([""]);
  const [filteredOptions, setFilteredOptions] = useState([...optionList]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let filteredList;

    const trimmedValue = e.target.value.trim();

    if (optionDesc) {
      filteredList = optionList?.filter(
        (option) =>
          option[nameDisplay]?.toLowerCase().includes(trimmedValue?.toLowerCase()) ||
          option[nameStore]?.toLowerCase().includes(trimmedValue?.toLowerCase())
      );
    } else {
      filteredList = optionList?.filter((option) =>
        option[nameDisplay]?.toLowerCase().includes(trimmedValue?.toLowerCase())
      );
    }
    setFilteredOptions(filteredList);
  };

  const handleOptionSelect: ComboboxProps["onOptionSelect"] = (e, option) => {
    e.preventDefault();

    const name = option?.optionText ?? "";
    const value = option?.optionValue ?? "";
    setSelectedValue(option.selectedOptions);

    optionSelectChange(name, value);
  };

  const handleOpenChange: ComboboxProps["onOpenChange"] = (e, data) => {
    e.preventDefault();

    if (data.open) {
      if (filteredOptions.length === 0) {
        setFilteredOptions([...optionList]);
      }
      if (filteredOptions.length > 0) {
        changeListHeight("fui-Listbox", 500);
      }
    }
  };

  useEffect(() => {
    if (isClearClicked) {
      setSelectedValue([""]);
      setClearClicked(false);
    }
  }, [isClearClicked]);

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={selectId} label={label} />}
        required={isMandatory}
        validationState={err ? "error" : "none"}
        validationMessage={err === "empty" ? "This field cannot be empty" : null}
      >
        <Combobox
          aria-labelledby={selectId}
          id={selectId}
          placeholder="..."
          selectedOptions={selectedValue}
          disabled={isDisabled}
          onChange={handleChange}
          onOptionSelect={handleOptionSelect}
          onOpenChange={handleOpenChange}
          size="large"
        >
          {optionListHiddenShow
            ? filteredOptions.map((option, idx) => (
                <Option
                  key={`select_${name}_option-${option[nameStore]}-${idx}`}
                  value={option[nameStore] || ""}
                  text={option[nameDisplay] || ""}
                >
                  <OptionDetails>
                    <p> {option[nameDisplay] || ""}</p>
                    {optionDesc && <p> {option[optionDesc] || ""}</p>}
                  </OptionDetails>
                </Option>
              ))
            : filteredOptions
                .filter(({ isHidden }) => !isHidden)
                .map((option, idx) => (
                  <Option
                    key={`select_${name}_option-${option[nameStore]}-${idx}`}
                    value={option[nameStore] || ""}
                    text={option[nameDisplay] || ""}
                  >
                    <OptionDetails>
                      <p> {option[nameDisplay] || ""}</p>
                      {optionDesc && <p> {option[optionDesc] || ""}</p>}
                    </OptionDetails>
                  </Option>
                ))}

          {filteredOptions.length === 0 ? (
            <Option key="no-results" disabled>
              No items found
            </Option>
          ) : null}
        </Combobox>
      </Field>
    </Wrapper>
  );
};

export default Select;
