import React from "react";
import styled from "styled-components";
import { makeStyles, tokens, Button, Tooltip } from "@fluentui/react-components";
import {
  ToggleLeft28Regular,
  WindowSettingsRegular,
  QuestionCircleRegular,
  WeatherMoonRegular,
  WeatherSunnyRegular,
} from "@fluentui/react-icons";

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 42px;
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 0 6px;
`;

const MainBtns = styled.div`
  display: flex;
  gap: 2px;
`;

const AddtionalBtns = styled.div`
  display: flex;
  gap: 2px;
`;

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    borderTopColor: tokens.colorNeutralStroke1,
  },
});

interface Props {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
}

const Footer: React.FC<Props> = ({ theme, setTheme }) => {
  const styles = useStyles();

  return (
    <Wrapper className={styles.wrapper}>
      <Inner>
        <MainBtns>
          <Tooltip content="Ribbon off" relationship="label">
            <Button size="large" appearance="subtle" icon={<ToggleLeft28Regular />} disabled />
          </Tooltip>
          <Tooltip content="Window Manager" relationship="label">
            <Button size="large" appearance="subtle" icon={<WindowSettingsRegular />} disabled />
          </Tooltip>
        </MainBtns>
        <AddtionalBtns>
          {theme === "light" ? (
            <Tooltip content="Dark Theme" relationship="label">
              <Button
                size="medium"
                appearance="subtle"
                icon={<WeatherMoonRegular />}
                onClick={() => setTheme("dark")}
              />
            </Tooltip>
          ) : (
            <Tooltip content="Light Theme" relationship="label">
              <Button
                size="medium"
                appearance="subtle"
                icon={<WeatherSunnyRegular />}
                onClick={() => setTheme("light")}
              />
            </Tooltip>
          )}
          <Tooltip content="Help" relationship="label">
            <Button size="large" appearance="subtle" icon={<QuestionCircleRegular />} disabled />
          </Tooltip>
        </AddtionalBtns>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
