/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Button, Spinner } from "@fluentui/react-components";

const Wrapper = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    width: 130px;
    height: 40px;
    cursor: "not-allowed";
  }
`;

interface Props {
  mainBtnTxt: string;
  secondaryBtnTxt: string;
  isMainBtnLoading: boolean;
  isMainBtnDisabled: boolean;
  isSecondaryBtnDisabled: boolean;
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
}

const Buttons: React.FC<Props> = ({
  mainBtnTxt,
  secondaryBtnTxt,
  isMainBtnLoading,
  isMainBtnDisabled,
  isSecondaryBtnDisabled,
  handleCancelClick,
  handleConfirmClick,
}) => {
  return (
    <Wrapper>
      <Button
        appearance="secondary"
        size="large"
        as="button"
        onClick={handleCancelClick}
        disabled={isSecondaryBtnDisabled}
      >
        {secondaryBtnTxt}
      </Button>
      <Button
        appearance="primary"
        size="large"
        as="button"
        onClick={() => {
          if (!isMainBtnLoading && !isMainBtnDisabled) {
            handleConfirmClick();
          }
        }}
        disabled={isMainBtnDisabled}
      >
        {isMainBtnLoading ? <Spinner size="tiny" /> : mainBtnTxt}
      </Button>
    </Wrapper>
  );
};

export default Buttons;
