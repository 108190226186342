import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { FluentProvider, Spinner } from "@fluentui/react-components";
import styled from "styled-components";
import { ClientInfoContext } from "../models/client";
import Initial from "./Initial";
import Footer from "./Footer";
import MainContent from "./MainContent";
import { fetchMainClientData } from "../utils/apiCalls";
import { lightTheme, darkTheme } from "../config/theme";
import { defaultContactLink } from "../utils/link";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const LoadSpinnerWrapper = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
`;

export const ClientContext = createContext<ClientInfoContext>({
  clientLogo: null,
  productLogo: null,
  title: "Add-In",
  contact: defaultContactLink,
});

const App = () => {
  const [theme, setTheme] = useState<string>("light");
  const [isAuth, setAuth] = useState<boolean>(false);

  const [isClientInfoLoading, setClientInfoLoading] = useState<boolean>(true);
  const [clientInfoErr, setClientInfoErr] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<ClientInfoContext>({
    clientLogo: null,
    productLogo: null,
    title: "Add-In",
    contact: defaultContactLink,
  });

  const [selectedValue, setSelectedValue] = useState<"tableWindow" | "instantReport" | "mailing">("instantReport");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      try {
        setClientInfoLoading(true);
        setClientInfoErr(false);

        const data = await fetchMainClientData(signal);

        if (data) {
          setClientInfo({
            clientLogo: data.logo,
            productLogo: data.productName,
            title: data.title,
            contact: data.contact,
          });
        } else {
          setClientInfoErr(true);
        }

        setClientInfoLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setClientInfoErr(true);
          setClientInfoLoading(false);
          setClientInfo({ clientLogo: null, productLogo: null, title: "Add-In", contact: defaultContactLink });
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  console.log(clientInfoErr);

  return (
    <FluentProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <ClientContext.Provider value={clientInfo}>
        <Wrapper>
          {isClientInfoLoading ? (
            <LoadSpinnerWrapper>
              <Spinner size="medium" />
            </LoadSpinnerWrapper>
          ) : (
            <>
              {!isAuth ? (
                <Initial setAuth={setAuth} />
              ) : (
                <MainContent selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
              )}
            </>
          )}
        </Wrapper>
        <Footer theme={theme} setTheme={setTheme} />
      </ClientContext.Provider>
    </FluentProvider>
  );
};

export default App;
