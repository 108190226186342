/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useId, Field, Combobox, Option, ComboboxProps } from "@fluentui/react-components";
import { Wrapper, OptionDetails } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { changeListHeight } from "../../utils/changeStyles";
import { SelectPara } from "../Modules/InstantReport";

interface Props {
  name: string;
  label: string;
  valueType: string;
  defaultValue: string;
  isMandatory: boolean;
  size: number;
  optionListKeys: string[] | [];
  optionList: any[];
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const ParaSelect: React.FC<Props> = ({
  name,
  label,
  valueType,
  defaultValue,
  isMandatory,
  size,
  optionListKeys,
  optionList,
  selectedPara,
  setSelectedPara,
}) => {
  const selectId = useId(`para-select_${name}`);
  const paraMainKey = optionListKeys[0];
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: defaultValue, isMandatory: isMandatory, type: valueType, err: null },
    }));

    let sortedOptionList;
    if (Array.isArray(optionList[0][paraMainKey])) {
      sortedOptionList = [...optionList];
    } else {
      sortedOptionList = optionList.sort((a, b) => a[paraMainKey].toString().localeCompare(b[paraMainKey].toString()));
    }

    setFilteredOptions(sortedOptionList);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim();

    const filteredList = optionList?.filter((option) => {
      {
        for (const key of optionListKeys) {
          return option[key].toLowerCase().includes(trimmedValue.toLowerCase());
        }
      }
    });
    setFilteredOptions(filteredList);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: trimmedValue, err: null },
    }));
    setSearchValue(trimmedValue);
  };

  const handleOptionSelect: ComboboxProps["onOptionSelect"] = (e, option) => {
    e.preventDefault();
    const textValue = option?.optionText ?? "";
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: textValue, err: null },
    }));
    setSearchValue(textValue);
  };

  const handleOpenChange: ComboboxProps["onOpenChange"] = (e, data) => {
    e.preventDefault();
    if (data.open) {
      if (filteredOptions.length === 0) {
        setFilteredOptions([...optionList]);
      }
      if (filteredOptions.length > 0) {
        changeListHeight("fui-Listbox", 340);
      }
    }
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={selectId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={
          targetSelectedParaObj?.err === "empty"
            ? "This field cannot be empty"
            : targetSelectedParaObj?.err === "exceed"
            ? `The maximum character limit for this field is ${size}. The number of characters entered is ${
                typeof targetSelectedParaObj?.value === "string" ? targetSelectedParaObj?.value.length : null
              }`
            : null
        }
      >
        <Combobox
          aria-labelledby={selectId}
          id={selectId}
          placeholder="..."
          onChange={handleChange}
          onOptionSelect={handleOptionSelect}
          onOpenChange={handleOpenChange}
          freeform
          size="large"
        >
          {filteredOptions.length === 0 ? (
            <Option key="search" text={searchValue} value={searchValue}>
              New value: {searchValue}
            </Option>
          ) : null}

          {filteredOptions.map((value, idx) => {
            if (Array.isArray(value[paraMainKey])) {
              return (
                <Option
                  key={`select_${name}_option-${value[paraMainKey][0]}-${idx}`}
                  value={value[paraMainKey][0]}
                  text={value[paraMainKey][0]}
                >
                  <OptionDetails>
                    {value[paraMainKey].map((key: string, idx: number) => (
                      <p key={`option_${value[paraMainKey][0]}_${key}-${idx}`}>{key}</p>
                    ))}
                  </OptionDetails>
                </Option>
              );
            } else {
              return (
                <Option
                  key={`select_${name}_option-${value[paraMainKey]}-${idx}`}
                  value={value[paraMainKey]}
                  text={value[paraMainKey]}
                >
                  <OptionDetails>
                    {optionListKeys.map((key, idx) => (
                      <p key={`option_${value[paraMainKey]}_${value[key]}-${idx}`}>{value[key]}</p>
                    ))}
                  </OptionDetails>
                </Option>
              );
            }
          })}
        </Combobox>
      </Field>
    </Wrapper>
  );
};

export default ParaSelect;
