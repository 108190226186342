import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { ErrorContent, Inner, Wrapper, BtnLink } from "./components/Initial";
import { messageList } from "./utils/messageList";
import Message from "./components/Message";
import { Button, FluentProvider } from "@fluentui/react-components";
import { darkTheme, lightTheme } from "./config/theme";
import { ClientContext } from "./components/App";
import { defaultContactLink } from "./utils/link";
import Header from "./components/Header";

/* global document, Office, module, require */
const container = document.getElementById('container');
let reactRoot;
let isOfficeReady = false;

const handleReload = () => {
    location.reload();
};

const defaultClientInfo = {
    clientLogo: "",
    productLogo: "../../assets/IFIS-title.svg",
    title: "Add-In",
    contact: defaultContactLink,
};

function InitPage() {
    return (
        <FluentProvider theme={"light" ? lightTheme : darkTheme}>
            <ClientContext.Provider value={defaultClientInfo}>
                <Header clientLogo={defaultClientInfo.clientLogo} productLogo={defaultClientInfo.productLogo} />
                <InitError />
            </ClientContext.Provider>
        </FluentProvider>
    );
}

function InitError() {
    const [countdown, setCountdown] = useState(7);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(val => --val);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (countdown <= 0) {
            handleReload();
        }
    }, [countdown]);

    return (
        <Wrapper>
            <Inner>
                <ErrorContent>
                    <Message
                        type="error"
                        title="Error"
                        desc={`${messageList.addInLoadErr} ${countdown} second${countdown !== 1 ? 's' : ''}. If the issue persists, please `}
                        isMainBtn={false}
                    />
                    <BtnLink>
                        <Button appearance="secondary" size="large" as="button" onClick={handleReload}>
                            Reload
                        </Button>
                    </BtnLink>
                </ErrorContent>
            </Inner>
        </Wrapper>
    );
}



async function initializeOffice() {
    let result;
    try {
        if (typeof Office === 'object')
            return true;
        let officeReady = await Office.onReady();
        if (officeReady)
            result = officeReady.host === Office.HostType.Excel;
    } catch (e) {
        result = false;
    }
    return result;
}

function render() {
    if (!reactRoot) {
        reactRoot = createRoot(container);
    }

    const interval = setInterval(async () => {
        const officeReady = await initializeOffice();
        if (officeReady) {
            isOfficeReady = true;
            clearInterval(interval);
            reactRoot.render(<App />);
        }
    }, 200);

    setTimeout(() => {
        if (!isOfficeReady) {
            reactRoot.render(<InitPage />);
        }
    }, 2000);

    const countdownInterval = setInterval(() => {
        if (isOfficeReady) {
            clearInterval(countdownInterval);
            clearInterval(interval);
        }
    }, 200);
}

render();

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        reactRoot.render(<NextApp />);
    });
}
