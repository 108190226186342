/* eslint-disable */
import React from "react";
import { Checkbox, Field } from "@fluentui/react-components";
import { Wrapper } from "./styles.common";

interface Props {
  label: string;
  isMandatory: boolean;
  isChecked: boolean;
  handleChange: () => void;
}

const StandardCheckbox: React.FC<Props> = ({ label, isMandatory, isChecked, handleChange }) => {
  return (
    <Wrapper className="wrapper_standard-Checkbox">
      <Field required={isMandatory}>
        <Checkbox label={label} checked={isChecked} onChange={handleChange} />
      </Field>
    </Wrapper>
  );
};

export default StandardCheckbox;
